<template>
  <el-row class="container">
    <el-col
      :sm="24"
      :lg="24"
      v-infinite-scroll="request"
      :infinite-scroll-distance="10"
      class="infinite-box"
    >
      <div v-for="(item, index) in lists.list" :key="index">
        <div class="padding flex align-center card" @click="toDetail(item.id)">
          <div class="card-img">
            <el-image
              fit="contain"
              :src="item.coverImg"
              style="width: 100%; height: 100%;"
            ></el-image>
          </div>

          <div
            class="flex flex-direction align-center justify-between card-context"
            style="padding-left: 0.5rem;"
          >
            <div class="text-28 text-bold title">{{ item.title }}</div>

            <div class="flex flex-direction align-center" style="width: 100%;">
              <div class="price">
                <template v-if="item.ticketNum == 0">
                  <div
                    v-if="!item.price || item.price == 0"
                    class="self-start flex-sub text-bold"
                    style="color: #ff4e00;"
                  >
                    免费
                  </div>
                  <div
                    v-else
                    class="margin-bottom-xs self-start text-bold"
                    style="color: #ff4e00;"
                  >
                    <span class="text-24">￥</span>
                    {{ item.price / 100 }}
                  </div>
                </template>

                <template v-else-if="item.ticketNum == 1">
                  <div
                    class="margin-bottom-xs self-start text-bold"
                    style="color: #ff4e00;"
                  >
                    <span class="text-24">￥</span>
                    {{ item.price / 100 }}
                    <span class="text-28">起</span>
                  </div>
                </template>
              </div>

              <div class="flex justify-between" style="width: 100%;">
                <div class="text-24 flex flex-direction">
                  <div class="address">
                    {{ filterCityInfo(item.city) }} |
                    {{ filterCityInfo(item.area) }}
                  </div>
                  <div class="time">
                    {{ startTime(item.startTime, item.endTime) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isNone" class="padding text-28 placeholder text-center">
        没有更多了
      </div>
      <div v-else class="padding text-28 placeholder text-center">
        上拉展示更多
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { request } from "@/utils/request";

export default {
  name: "latestActivity",
  data() {
    return {
      // 是否正在下拉加载
      isLoading: false,
      // 是否已经加载完成
      isNone: false,

      // 最新列表
      lists: {
        list: [],
        limit: 10,
        page: 1
      }
    };
  },
  beforeMount() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.isNone = false;
      this.lists.list = [];
      this.lists.page = 1;

      this.request();
    },
    request() {
      console.log("trigger");
      return new Promise((resolve, reject) => {
        if (this.isLoading) return;

        this.isLoading = true;
        let url =
          "https://xinke.thinkervc.cn/api/applet/activityInfo/getActivityInfoList?pageSize=" +
          this.lists.limit +
          "&pageNum=" +
          this.lists.page;

        request(url, {}).then(res => {
          if (res.code == 200) {
            if (
              Math.ceil(res.data.total / this.lists.limit) >= this.lists.page
            ) {
              let newData = res.data.records;
              newData.forEach((v, k) => {
                this.lists.list.push(v);
              });

              if (
                Math.ceil(res.data.total / this.lists.limit) === this.lists.page
              ) {
                this.isLoading = false;
                this.isNone = true;
                resolve();
              } else {
                this.lists.page++;
                this.isLoading = false;
                resolve();
              }
            } else {
              // console.log("end");
              this.isLoading = false;
              this.isNone = true;
              resolve();
            }
          } else {
            this.isLoading = false;
            this.isNone = true;
            reject();
          }
        });
      });
    },
    toDetail(index) {
      this.$router.push({
        name: "latestActivityDetail",
        query: { id: index, type: 1 }
      });
    }
  },
  computed: {
    // 因为地址是 城市编码 与 城市名称拼接
    filterCityInfo() {
      return function(cityInfo) {
        let res = [];
        if (!cityInfo) {
          return "";
        }
        let list = cityInfo.split(",");
        if (list && list.length >= 2) {
          res = list.splice(1, 1).join(" ");
        } else {
          res = list.splice(0, 1).join(" ");
        }
        return res;
      };
    },
    // 计算最新的开始时间
    startTime() {
      return function(start, end) {
        let sDT = start.split(" ");
        let eDT = end.split(" ");
        if (sDT[0] == eDT[0]) {
          let sTime = sDT[1].split(":");
          let eTime = eDT[1].split(":");
          return (
            sDT[0] +
            " " +
            sTime[0] +
            ":" +
            sTime[1] +
            "-" +
            eTime[0] +
            ":" +
            eTime[1]
          );
        } else {
          let eDate = eDT[0].split("-");
          return sDT[0] + " 至 " + eDate[1] + "-" + eDate[2];
        }
      };
    },
    // 计算地址展现方式
    address() {
      return function(value) {
        if (value && value.length > 6) {
          let reg = /.+?(省|市|自治区|自治州|县|区)/g; // 省市区的正则
          let temparr = value.match(reg);
          // 过滤数组
          let arr = temparr.filter((item, index, arr) => {
            return !item.includes("省");
          });
          let result = "";
          // 处理数组
          arr.map((item, index) => {
            if (arr.length < 2) {
              result = item;
            } else {
              if (index == 0) {
                result = item;
              } else {
                result += "丨" + item;
              }
            }
          });
          return result;
        } else {
          return value;
        }
      };
    }
  }
};
</script>

<style scoped lang="scss">
.padding {
  padding: 1rem;
}

.flex {
  flex-wrap: nowrap !important;
}

.container {
  width: 100%;
  height: 100vh;

  .infinite-box {
    width: 100%;
    height: 100%;
    overflow: auto;

    .card {
      display: flex;
      width: 100%;
      height: 9.375rem;
      border-bottom: 1px solid #f3f3f3;

      .card-img {
        position: relative;
        flex: 1;
        height: 8rem;
        border-radius: 0.3125rem;
        overflow: hidden;
      }

      .card-context {
        width: 52%;
        height: 8rem;

        .title {
          width: 100%;
          height: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .price {
          margin-bottom: 0.0625rem;
          width: 100%;
          font-size: 14px;
        }

        .address,
        .time {
          margin-top: 0.1875rem;
          font-size: 14px;
          letter-spacing: 0;
          color: #959595;
        }
      }
    }
  }
}
</style>
